import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getAuthToken } from "../../../components/src/NativeWebRouteWrapper/Utils";
export interface OtherInterestFormField {
  name: string,
  interest_category: string,
  created_by_admin: boolean
}
const otherInterestAddMoreField: OtherInterestFormField = {
  name: "", interest_category: "", created_by_admin: false
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleOpen:any;
  openOtherComponent:any;
  profileImage:any
  // Customizable Area End
}

export interface S {
  id: string;
  // Customizable Area Start
  openOtherComponent: string;
  interests: any;
  selectedInterests: any;
  list: any;
  uploadProfilePhoto: any;
  uploadCoverPhoto: any;
  value: string;
  groupName: string;
  description: string;
  error: string;
  coverPhotoPreview: any;
  profilePhotoPreview: any;
  interestIds: number[],
  selected_interest:any,
  otherInterestImpactFields: OtherInterestFormField[];
  otherInterestSkillsFields: OtherInterestFormField[];
  showOtherImpactFields: boolean;
  showOtherSkillsFields: boolean;
  impactInterestUniqueError: string;
  skillsInterestUniqueError: string;
  groupNameError: string;
  groupTypeError: string;
  userInterestError: string;
  coverPhotoKey: string,
  iconPhotoKey: string,
  removedInterestIds:any,
  interestIdsForRemove:any,
  interestIdsForSelect:any,
  selectedInterestIds:any,
  groupInterests:any,
  location: string,
  imageError: boolean
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateNewGroupController extends BlockComponent<
  Props,
  S,
  SS
>
    {
      // Customizable Area Start
      getInterestsApiCallId = "";
      postApiCallId = "";
      token = getAuthToken();
      mediaArr: any = []
      // Customizable Area End

  constructor(props: Props) {
    super(props);
this.subScribedMessages = [
  // Customizable Area Start
  getName(MessageEnum.RestAPIResponceMessage),
  getName(MessageEnum.SessionResponseMessage),
  // Customizable Area End
];

this.state = {
  // Customizable Area Start
  id: "",
  openOtherComponent: "",
  interests: [],
  selectedInterests: [],
  list: [{ name: '' }],
  uploadProfilePhoto: null,
  uploadCoverPhoto: null,
  value: '',
  groupName: '',
  description: '',
  error: '',
  coverPhotoPreview: '',
  profilePhotoPreview: '',
  interestIds:[],
  interestIdsForRemove:[],
  selected_interest:[],
  showOtherImpactFields: false,
  showOtherSkillsFields: false,
  otherInterestImpactFields: [{ ...otherInterestAddMoreField }],
  otherInterestSkillsFields: [{ ...otherInterestAddMoreField }],
  impactInterestUniqueError: "",
  skillsInterestUniqueError: "",
  groupNameError: "",
  groupTypeError: "",
  userInterestError: "",
  coverPhotoKey:"",
  iconPhotoKey:"",
  removedInterestIds:[],
  interestIdsForSelect:[],
  selectedInterestIds: [],
  groupInterests:[],
  location: "",
  imageError: false
  // Customizable Area End
};

runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

// Customizable Area Start
this.receive = this.receive.bind(this);
// Customizable Area End

  }

  // Customizable Area Start
   receive = async(from: string, message: any) => {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallIds = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJsons = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallIds === this.getInterestsApiCallId) {
          this.setState({ interests: responseJsons.data});
      }
    }
  }

   async componentDidMount() {
    super.componentDidMount();
    window.scroll(0,0)
    this.setState({location: window.location.pathname})
    this.getInterests()
    const group = JSON.parse(localStorage.getItem("edit_group")!)
    this.handleDataForEditGroup(group)
   }

  handleDataForEditGroup = (group:any) => {
    this.setState({
      uploadCoverPhoto:group.attributes.cover_image,
      uploadProfilePhoto:group.attributes.icon_image,
      coverPhotoPreview:group.attributes.cover_image,
      profilePhotoPreview:group.attributes.icon_image,
      groupName:group.attributes.name,
      description:group.attributes.group_description,
      value:group.attributes.group_type,
      interestIds:group.attributes.interests.map((interest:any) => String(interest.id)),
      interestIdsForRemove:group.attributes.interests.map((interest:any) => String(interest.id)),
      interestIdsForSelect:group.attributes.interests.map((interest:any) => String(interest.id)),
      groupInterests:group.attributes.group_interest && group.attributes.group_interest.map((interest:any) => {
        return {id: String(interest.id), interest_id: String(interest.interest_id)}
      }),
      selected_interest:group.attributes.interests.map((interest:any) => {
       return {"interest_id":String(interest.id)}
      }),
    })
  }

  getInterests = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getInterestsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getInterestsApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleOtherFields = (e: any, category: string, index: number) => {
    if (category === 'impact_area') {
      let otherFields = [...this.state.otherInterestImpactFields];
      otherFields[index] = { ...otherFields[index], name: e.target.value, interest_category: category };
      this.setState({ otherInterestImpactFields: otherFields, userInterestError:"" });
    } else {
      let otherSkillFields = [...this.state.otherInterestSkillsFields];
      otherSkillFields[index] = { ...otherSkillFields[index], name: e.target.value, interest_category: category };
      this.setState({ otherInterestSkillsFields: otherSkillFields, userInterestError:"" });
    }
  }

  handleFormFields =(index: number,category: string, type:string) => {
    if (category === "impact_area") {
      const otherInterestImpactFieldData = this.state.otherInterestImpactFields;
      if(type==="add"){
        otherInterestImpactFieldData.push({ ...otherInterestAddMoreField });
      } else{
        otherInterestImpactFieldData.splice(index,1);
      }
      this.setState({ otherInterestImpactFields: otherInterestImpactFieldData })
    } else {
      const otherInterestSkillsFieldData = this.state.otherInterestSkillsFields;
      if(type==="add"){
        otherInterestSkillsFieldData.push({ ...otherInterestAddMoreField });
      } else{
        otherInterestSkillsFieldData.splice(index,1);
      }
      this.setState({ otherInterestSkillsFields: otherInterestSkillsFieldData })
    }
  }

  handleSelectInterest = (id: any) => {
    if (this.state.interestIds.includes(id)) {
      let idArray = [...this.state.interestIds];
      let selectedIdArray = [...this.state.selected_interest];
      idArray = idArray.filter(item => item !== id);
      const indxKey = selectedIdArray.findIndex(item => item.interest_id === id)
      selectedIdArray.splice(indxKey, 1)
      let removedInterestIds = [...this.state.removedInterestIds];
      if(this.state.interestIdsForRemove.includes(id)){
        removedInterestIds.push(id)
      }
      this.setState({selected_interest: selectedIdArray , interestIds: idArray, removedInterestIds});
    } else {
      const interest_arr = this.state.selected_interest;
      interest_arr.push({ 'interest_id': id });
      const interest_id_arr = [...this.state.interestIds];
      interest_id_arr.push(id);
      let selectedInterestIds = [...this.state.selectedInterestIds]
      if(!this.state.interestIdsForSelect.includes(id)){
        selectedInterestIds.push({'interest_id': id})
      }
      this.setState({ selected_interest: interest_arr , interestIds: interest_id_arr, userInterestError:"", selectedInterestIds });
    }
  }

  handleShowFields = (type: string) => {
    const {showOtherImpactFields, showOtherSkillsFields} = this.state
    if (type === "impact_area") {
        this.setState({ showOtherImpactFields: !showOtherImpactFields, 
          otherInterestImpactFields: showOtherImpactFields ? [] : [{ ...otherInterestAddMoreField }] })
    } else {
        this.setState({ showOtherSkillsFields: !showOtherSkillsFields,
           otherInterestSkillsFields: showOtherSkillsFields ? [] :   [{ ...otherInterestAddMoreField }]})
    }
  }

  handleSetState = (event: any, name:string, type:string) => {
    if (event && event.target) {
      if(type==="text"){
        this.setState({ ...this.state, [name]: event.target.value.trimStart() })
      }else{
        const file = event.target.files[0]
        if (file.size < 10 * 1024 * 1024 && (file.type === "image/jpg" || file.type === "image/png")) {
          this.setState({ ...this.state, [name]: file, imageError: false })
          this.readFileAndSetPhotoPreview(file, name)
          return
        }
        this.setState({
          imageError: true
        })
      }
    }
  }

  handleGroupSetting = (setting: string)=> {
    this.setState({value: setting })
  }

  readFileAndSetPhotoPreview = (file: any, name:string) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event && event.target) {
        if(name === "uploadCoverPhoto"){
          this.setState({coverPhotoPreview: event.target.result, coverPhotoKey:"newUpload" });
        } else{
          this.setState({profilePhotoPreview: event.target.result, iconPhotoKey:"newUpload" });
        }
      }
    };
    reader.readAsDataURL(file);
  };

 handleOpenGroupType = () =>{
    document.body.classList.add('my-class')
 }

 handleCloseGroupType = () =>{
   document.body.classList.remove('my-class')
 }
  // Customizable Area End
}