import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getAuthToken } from "../../../components/src/NativeWebRouteWrapper/Utils";
import i18next, {t} from "i18next";
export interface OtherInterestFormField {
  name: string,
  interest_category: string,
  created_by_admin: boolean
}

export interface OtherInterestSkillsFormField {
  name: string,
  interest_category: string,
  created_by_admin: boolean
}
const otherInterestSkillsAddMoreField : OtherInterestSkillsFormField = {
  name: "", interest_category: "", created_by_admin: false

}
const otherInterestAddMoreField: OtherInterestFormField = {
  name: "", interest_category: "", created_by_admin: false
};

interface IObjectKeys {
  [key: string]: [];
}

interface ProfileInitialValues {
  full_name: string;
  email: string;
  full_phone_number: string;
  gender: string,
  country: string,
  state: string,
  city: string,
  profession: string,
  preferred_language: number | string;
  selected_interest: any[];
  partnership_practitioner: string,
  accredited_partnership_broker: string,
  organizational_affilation: string,
  interest_name: any[]
  skill_name: any[]
  about_yourself: string
  experience: string
  photo: any
  working_language: string
  time_zone: string
  connections: any[]
}

interface ProfileErrorValues {
  nameError: string;
  emailError: string;
  phoneError: string;
  genderError: string,
  countryError: string,
  stateError: string,
  cityError: string,
  professionError: string,
  languageError: number | string;
  partnershipError: string,
  brokerError: string,
  affiliationsError: string
  experienceError: string
  aboutError: string
}

interface PasswordInitialValues {
  current_password: string;
  new_password: string;
  confirm_password: string;
}

interface PasswordErrorValues {
  currentPasswordError: string;
  newPasswordError: string;
  confirmPasswordError: string;
}

interface ValidateFields {
  key: string, label: string, regexp: RegExp | null, errorKey: string
}

type Color = "success" | "info" | "warning" | "error" | undefined;

const ProfileFormField: ProfileInitialValues = { connections: [] , time_zone: '', working_language: '', photo: '', full_name: "", email: "", full_phone_number: "", gender: "", country: "", state: "", city: "", profession: "", preferred_language: '', selected_interest: [], partnership_practitioner: "", accredited_partnership_broker: "", organizational_affilation: "", interest_name: [], skill_name: [], about_yourself: '', experience: '' };
const ProfileErrorField: ProfileErrorValues = { nameError: "", emailError: "", phoneError: "", genderError: "", countryError: "", stateError: "", cityError: "", professionError: "", languageError: '', partnershipError: "", brokerError: "", affiliationsError: "", experienceError: "", aboutError: "" };

const PasswordFormField: PasswordInitialValues = { current_password: "", new_password: "", confirm_password: "" };
const PasswordErrorField: PasswordErrorValues = { currentPasswordError: "", newPasswordError: "", confirmPasswordError: "" };

const Language: any = { english: "en", french: "fr", bengali: "bn" }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  activePage: string;
  showCurrentPassword: boolean;
  showChangePassword: boolean;
  showConfirmPassword: boolean;
  interestList: IObjectKeys;
  showOtherSkillsFields: boolean,
  showOtherImpactFields: boolean,
  otherInterestImpactFields: OtherInterestFormField[];
  otherInterestSkillsFields: OtherInterestSkillsFormField[];
  ProfileFormField: ProfileInitialValues;
  ProfileErrorField: ProfileErrorValues;
  PasswordFormField: PasswordInitialValues;
  PasswordErrorField: PasswordErrorValues;
  countryList: any[];
  stateList: any[];
  cityList: any[];
  userInfo: any;
  interestIds: number[],
  selected_interest:any,
  showSnackbar: boolean;
  severity: Color;
  message: string;
  imagePreview: any
  updatedProfileImage: any
  staticInterestList: any[]
  staticInterestSelectedList: any[]
  staticSkillList: any[]
  staticSkillSelectedList: any[]
  imageError: boolean
  pbaConnectionsList: any[]
  phoneCountryObject: any
  timezoneList: any[]
  workingLanguageList: any[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings5Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetInterest: any;
  apiGetCountry: any;
  apiGetState: any;
  apiGetCity: any;
  apiEditProfile: any;
  apiEditPassword: any;
  token = getAuthToken();
  apiPBAconnections: any;
  userInterestList: any;
  userSkillList: any;
  apiTimezones: any;
  workingLanguageApiCallId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.NavigationPayLoadMessage),
        getName(MessageEnum.CountryCodeMessage)
      
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      activePage: 'profile',
      showCurrentPassword: false,
      showChangePassword: false,
      showConfirmPassword: false,
      interestList: {},
      showOtherSkillsFields: false,
      showOtherImpactFields: false,
      otherInterestImpactFields: [{ ...otherInterestAddMoreField }],
      otherInterestSkillsFields: [{ ...otherInterestSkillsAddMoreField }],
      ProfileFormField: ProfileFormField,
      ProfileErrorField: ProfileErrorField,
      PasswordFormField: PasswordFormField,
      PasswordErrorField: PasswordErrorField,
      countryList: [],
      stateList: [],
      cityList: [],
      userInfo: {},
      interestIds: [],
      selected_interest: [],
      showSnackbar: false,
      severity: undefined,
      message: "",
      imagePreview: null,
      updatedProfileImage: null,
      staticInterestList: [],
      staticInterestSelectedList: [],
      staticSkillList: [],
      staticSkillSelectedList: [],
      imageError: false,
      pbaConnectionsList: [],
      phoneCountryObject: {},
      timezoneList: [],
      workingLanguageList: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const apiSettingRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );

      switch (apiSettingRequestCallId) {
        case this.apiGetInterest:
          this.setState({ interestList: responseJson?.data });
          break;
        case this.apiGetCountry:
          if (responseJson.error) {
            console.log('errored at get state api', responseJson.error)
            this.setState({ countryList: [] })
            break
          }
          this.setState({ countryList: responseJson.data }, () => {
            if (this.state.ProfileFormField?.country) {
              const countryObj = responseJson?.data?.find((item: any) => item?.attributes?.name === this.state.ProfileFormField?.country)
              this.getStateList(countryObj?.id)
            }
          });
        case this.apiGetState:
          if (responseJson.error) {
            console.log('errored at get state api', responseJson.error)
            this.setState({ stateList: [] })
            break
          }
          this.setState({ stateList: responseJson.data }, () => {
            if (this.state.ProfileFormField?.state) {
              const countryObj = this.state.countryList?.find((item: any) => item?.attributes?.name === this.state.ProfileFormField?.country)
              const stateObj = responseJson?.data?.find((item: any) => item?.name === this.state.ProfileFormField?.state)
              this.getCityList(countryObj?.id, stateObj?.id)
            }
          });
          break
        case this.apiGetCity:
          if (responseJson.error) {
            console.log('errored at get city api', responseJson.error)
            this.setState({ cityList: [] })
            break
          }
          this.setState({ cityList: responseJson.data });
          break;
        case this.apiPBAconnections:
          if (responseJson.error) {
            console.log('errored at get city api', responseJson.error)
            break
          }
          this.setState({ pbaConnectionsList: responseJson.data })
          break
        case this.userInterestList:
          if (responseJson.error) {
            console.log('errored at get interest api', responseJson.error)
            break
          }
          this.populateInterestData(responseJson.data)
          break
        case this.userSkillList:
          if (responseJson.error) {
            console.log('errored at get skill api', responseJson.error)
            break
          }
          this.populateSkillsData(responseJson.data)
          break
        case this.apiTimezones:
          if (responseJson.error) {
            console.log('errored at get timezone api', responseJson.error)
            break
          }
          this.setState({timezoneList: responseJson.data})
          break
        case this.workingLanguageApiCallId:
          this.workingLanguageApiHandler(responseJson)
          break
        case this.apiEditProfile:
          this.findUserEditApiCaller(responseJson);
          break;
          case this.apiEditPassword:
            if (responseJson?.message) {
              this.setState({
                PasswordFormField: PasswordFormField,
                showSnackbar: true,
                message: responseJson.message,
                severity: "success",
              });
              this.props.navigation.navigate("UserProfileBasicBlock")
            
              break;
            }
            this.setState({
              showSnackbar: true,
              message: responseJson?.errors?.message,
              severity: "error",
            });
            break;
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    const userInfo = JSON.parse(localStorage.getItem("userInfo")!)
    const interest_arr: any[] = userInfo.user_interests?.map((item: any) => ({ interest_id: item?.interest_id.toString() }))  || []
    const interest_id_arr: any[] = userInfo.user_interests?.map((item: any) => item?.interest_id.toString()) || []
    this.setState({ userInfo, 
      selected_interest: interest_arr, 
      interestIds: interest_id_arr,
      ProfileFormField: { ...userInfo, accredited_partnership_broker: userInfo?.accredited_partnership_broker.toString() } 
    })
    i18next.changeLanguage(Language[userInfo.preferred_language.toLowerCase()]);
    this.getInterestList();
    this.getCountryList();
    this.getPBAConnections();
    this.getUserInterestList();
    this.getUserSkillList();
    this.getTimezonesList();
    this.getWorkingLanguages();
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {this.setState({ txtInputValue: text})},
    secureTextEntry: false
  };

  txtInputMobileProps = {...this.txtInputWebProps, autoCompleteType: "email", keyboardType: "email-address"};

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible;
    },
  };

  txtInputProps = this.isPlatformWeb() ? this.txtInputWebProps : this.txtInputMobileProps;

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  btnExampleProps = {onPress: () => this.doButtonPressed()};

  setEnableField = () => {this.setState({enableField: !this.state.enableField})};

  // web events
  setInputValue = (text: string) => {this.setState({ txtInputValue: text })};

  // Customizable Area Start
  handleAddNewInterest = (data: any) => {
    const transformedData = { ...data, created_by_admin: false, interest_category: 'impact_area' }
    this.setState({
      staticInterestList: [...this.state.staticInterestList, this.reverseTransformObject(transformedData)]
    })
    this.handleAddExistingInterest(this.reverseTransformObject(transformedData))
  }

  populateInterestData=(data: any)=>{
    this.setState({ staticInterestList: data })
    const managedSelectedInterests = this.state.staticInterestList.filter((item: any)=> this.state.ProfileFormField.interest_name.some(
      (interestOne: any)=> interestOne.id == item.id))
      this.setState({staticInterestSelectedList: managedSelectedInterests})
  }

  updateInterestData=()=>{
    const managedSelectedInterests = this.state.staticInterestList.filter((item: any)=> this.state.ProfileFormField.interest_name.some(
      (interestOne: any)=> interestOne.id == item.id))
      this.setState({staticInterestSelectedList: managedSelectedInterests})
  }

  populateSkillsData=(data: any)=>{
    this.setState({ staticSkillList: data })
    const managedSelectedSkills = this.state.staticSkillList.filter((item: any)=> this.state.ProfileFormField.skill_name.some(
      (skill: any)=> skill.id == item.id))
      this.setState({staticSkillSelectedList: managedSelectedSkills})
  }

  updateSkillsData=()=>{
    const managedSelectedSkills = this.state.staticSkillList.filter((item: any)=> this.state.ProfileFormField.skill_name.some(
      (skill: any)=> skill.id == item.id))
      this.setState({staticSkillSelectedList: managedSelectedSkills})
  }

  handleAddExistingInterest = (data: any) => {
    this.setState({
      staticInterestSelectedList: [...this.state.staticInterestSelectedList, data]
    })
  }

  handleDeleteExistingInterest = (data: any) => {
    const filtered = this.state.staticInterestSelectedList.filter((item) => item.id != data.id)
    this.setState({ staticInterestSelectedList: filtered })
  }

  reverseTransformObject = (transformedObject: any) => {
    const {
      id,
      name,
      created_by_admin,
      interest_category,
    } = transformedObject;

    const originalObject = {
      id: id.toString(),
      attributes: {
        name: name,
        created_by_admin: created_by_admin,
        interest_category: interest_category,
      }
    };

    return originalObject;
  };

  transformObject = (originalObject: any) => {
    const { id, attributes } = originalObject;

    const transformedObject = {
      id: parseInt(id, 10),
      name: attributes.name,
      created_by_admin: attributes.created_by_admin,
      interest_category: attributes.interest_category,
    };

    return transformedObject;
  };

  handleAddToUserInterest = () => {
    const transformedList = this.state.staticInterestSelectedList.map(this.transformObject)
    const filteredUserArray = this.state.ProfileFormField.interest_name.filter(obj1 =>
      transformedList.some(obj2 => obj2.id === obj1.id)
    );
    const newArray = [...filteredUserArray];
    transformedList.forEach(obj2 => {
      if (!newArray.some(obj => obj.id === obj2.id)) {
        newArray.push(obj2);
      }
    });
    this.setState({
      ProfileFormField: { ...this.state.ProfileFormField, interest_name: [...newArray] },
    })
  }

  handleAddNewSkill = (data: any) => {
    const transformedData = { ...data, created_by_admin: false, interest_category: 'skills_and_approaches' }
    this.setState({
      staticSkillList: [...this.state.staticSkillList, this.reverseTransformObject(transformedData)]
    })
    this.handleAddExistingSkill(this.reverseTransformObject(transformedData))
  }

  handleAddExistingSkill = (data: any) => {
    this.setState({
      staticSkillSelectedList: [...this.state.staticSkillSelectedList, data]
    })
  }

  handleDeleteExistingSkill = (data: any) => {
    const filtered = this.state.staticSkillSelectedList.filter((item) => item.id != data.id)
    this.setState({ staticSkillSelectedList: filtered })
  }

  handleAddToUserSkill = () => {
    const transformedList = this.state.staticSkillSelectedList.map(this.transformObject)
    const filteredUserArray = this.state.ProfileFormField.skill_name.filter(obj1 =>
      transformedList.some(obj2 => obj2.id === obj1.id)
    );
    const newArray = [...filteredUserArray];
    transformedList.forEach(obj2 => {
      if (!newArray.some(obj => obj.id === obj2.id)) {
        newArray.push(obj2);
      }
    });
    this.setState({
      ProfileFormField: { ...this.state.ProfileFormField, skill_name: [...newArray] },
    })
  }

  removeLeadingSpaces=(htmlString: string)=> {
    const cleanedString = htmlString
      .replace(/<p>(?:\s|&nbsp;|<br>)*<\/p>/g, '')
      .replace(/<p>\s*<br>\s*<\/p>/g, '')
      .replace(/<p>\s*(?:&nbsp;|\s|<br>)+/g, '<p>')
      .replace(/^\s*&nbsp;*/, '')
      .replace(/<br>\s*/g, '')
      .replace(/<\/p>\s*<p>/g, '')
      .replace(/<p>\s*<\/p>/g, '');
    return cleanedString;
}

  handleOASText = (value: string) => {
    this.setState({ ProfileFormField: { ...this.state.ProfileFormField, organizational_affilation: this.removeLeadingSpaces(value) } });
  };

  handleSEText = (value: string) => {
    this.setState({ ProfileFormField: { ...this.state.ProfileFormField, experience: this.removeLeadingSpaces(value) } });
  };

  deleteSkillsData = (chipToDelete: any) => {
    const newData =
      this.state.ProfileFormField.skill_name.filter((chip: any) => chip.name !== chipToDelete.name)
    this.setState({ ProfileFormField: { ...this.state.ProfileFormField, skill_name: newData } })
  }

  deleteInterestsData = (chipToDelete: any) => {
    const newData =
      this.state.ProfileFormField.interest_name.filter((chip: any) => chip.name !== chipToDelete.name)
    this.setState({ ProfileFormField: { ...this.state.ProfileFormField, interest_name: newData } })
  }

  handleConnectionList = (value: any) => {
    const lastObj = value[value.length - 1]
    if (value.length === 0) {
      this.setState({ ProfileFormField: { ...this.state.ProfileFormField, connections: [] } })
      return
    }
    const alreadySelected = this.state.ProfileFormField.connections.some(selected => selected.id == lastObj.id);
    if (alreadySelected) {
      this.setState({ ProfileFormField: { ...this.state.ProfileFormField, connections: value } })
      return
    }
    this.setState({
      ProfileFormField: {
        ...this.state.ProfileFormField,
        connections: [...this.state.ProfileFormField.connections, {
          name: lastObj.attributes.name,
          account_id: lastObj.attributes.account_id,
          created_at: lastObj.attributes.created_at,
          updated_at: lastObj.attributes.updated_at,
          id: lastObj.id
        }]
      }
    })
  }

  handleImageSelect = (event: any) => {
    const file = event.target?.files![0]
    if (file.size < 10 * 1024 * 1024 && (file.type === "image/jpg" || file.type === "image/png")) {
      this.setState({
        ProfileFormField: { ...this.state.ProfileFormField, photo: '' }
      })
      this.setState({
        imagePreview: URL.createObjectURL(file),
        imageError: false,
        updatedProfileImage: file
      })
      return
    }
    this.setState({
      imageError: true
    })
  };

  findUserEditApiCaller = (responseJson: any) => {
    if (responseJson?.data) {
              const res = {
                id: responseJson.data.id,
                ...responseJson.data.attributes,
              }
                this.setState({
                  showSnackbar: true,
                  message: t('Profile edit successfully'),
                  severity: "success",
                  userInfo: res 
                });
                localStorage.setItem(
                  "userInfo",
                  JSON.stringify(res)
                );
                setTimeout(() => {
                  this.props.navigation.navigate("UserProfileBasicBlock");
              }, 1000);
            } else if(responseJson?.errors){
              this.setState({
                showSnackbar: true,
                message: responseJson?.errors?.message,
                severity: "error",
              });
            }
  }
  handleOtherFields = (e: any, category: string, index: number) => {
      let state = {...this.state}
      let otherFields = (category === 'impact_area') ? [...state.otherInterestImpactFields] : [...state.otherInterestSkillsFields];
      otherFields[index] = { ...otherFields[index], name: e.target.value, interest_category: category };
      if(category === 'impact_area'){
        this.setState({  otherInterestImpactFields: otherFields});
      }else{
        this.setState({  otherInterestSkillsFields: otherFields});
      }
  }

  handleAddFormFields = (type: string) => {
    let state = {...this.state}
    let otherInterestFieldData = (type === 'impact_area') ? [...state.otherInterestImpactFields] : [...state.otherInterestSkillsFields];
    otherInterestFieldData.push({ ...otherInterestAddMoreField });
    if(type === 'impact_area'){
      this.setState({  otherInterestImpactFields: otherInterestFieldData});
    }else{
      this.setState({  otherInterestSkillsFields: otherInterestFieldData});
    }
  }
  
  handleRemoveAddMoreFields =(index: number,category: string) => {
    let state = {...this.state}
    let otherInterestFieldData = (category === 'impact_area') ? [...state.otherInterestImpactFields] : [...state.otherInterestSkillsFields];
    otherInterestFieldData.splice(index,1);
    if(category === 'impact_area'){
      this.setState({  otherInterestImpactFields: otherInterestFieldData});
    }else{
      this.setState({  otherInterestSkillsFields: otherInterestFieldData});
    }
  }
  handleShowCurrentPassword = () => {
    this.setState({ showCurrentPassword: !this.state.showCurrentPassword });
  };
  handleShowChangePassword = () => {
    this.setState({ showChangePassword: !this.state.showChangePassword });
  };
  handleShowConfirmPassword = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  };
  handleActiveTab= (type:string) => {
    this.setState({ activePage: type });
  };

  handleChange = (e: any) => {
    let value = e.target.value;

    if (e.target.name === "country") {
      const countryObj = this.state.countryList?.find((item: any) => item?.attributes?.name === value)
      this.getStateList(countryObj?.id)
      this.setState({ ProfileFormField: { ...this.state.ProfileFormField, [e.target.name]: value } });
    } else if (e.target.name === "state") {
      const countryObj = this.state.countryList?.find((item: any) => item?.attributes?.name === this.state.ProfileFormField.country)
      const stateObj = this.state.stateList?.find((item: any) => item?.name === value)
      this.getCityList(countryObj?.id, stateObj?.id)
      this.setState({ ProfileFormField: { ...this.state.ProfileFormField, [e.target.name]: value } });
    } else if (e.target.name === "city" || e.target.name === "preferred_language") {
      this.setState({ ProfileFormField: { ...this.state.ProfileFormField, [e.target.name]: value } });
    } else if (e.target.name === "number_visible") {
      this.setState({ ProfileFormField: { ...this.state.ProfileFormField, [e.target.name]: e.target.checked } });
    } else {
      this.setState({ ProfileFormField: { ...this.state.ProfileFormField, [e.target.name]: value.trimStart() } });
    }
  };

  handlePhoneNumberChange=(value: any, countryObj: any, event: React.ChangeEvent<HTMLInputElement>, formattedValue: string)=>{
    this.setState({ProfileFormField: {...this.state.ProfileFormField, full_phone_number: value}, phoneCountryObject: countryObj})
  }

  handleCountryChange=(value: any)=>{
    this.setState((prevState) => {
      const shouldClearStates = prevState.ProfileFormField.country !== value?.attributes.name;
      shouldClearStates && value?.attributes.name != undefined &&  this.getStateList(value.id)
      return {
        ProfileFormField: {
          ...prevState.ProfileFormField,
          country: value?.attributes.name === undefined ? '' : value?.attributes.name,
          state: shouldClearStates ? '' : prevState.ProfileFormField.state,
          city: shouldClearStates ? '' : prevState.ProfileFormField.city,
        }        
      };
    });
  }

  handleStateChange=(value: any)=>{
    this.setState((prevState) => {
      const shouldClearStates = prevState.ProfileFormField.state !== value?.name;
      const countryObj = this.state.countryList.find((item: any) => item.attributes.name === this.state.ProfileFormField.country)
      shouldClearStates && value?.name != undefined && this.getCityList(countryObj?.id, value?.id)
      return {
        ProfileFormField: {
          ...prevState.ProfileFormField,
          state: value?.name === undefined ? '' : value?.name,
          city: shouldClearStates ? '' : prevState.ProfileFormField.city,
        },
      };
    });
  }

  handleTimeChange=(value: any)=>{
    this.setState((prevState) => {
      return {
        ProfileFormField: {
          ...prevState.ProfileFormField,
          time_zone: value?.name === undefined ? '' : value.name,
        },
      };
    });
  }

  handleCityChange=(value: any)=>{
    this.setState((prevState) => {
      return {
        ProfileFormField: {
          ...prevState.ProfileFormField,
          city: value?.name === undefined ? '' : value?.name,
        },
      };
    });
  }

  handleWorkingLanguageChange=(value: any)=>{
    this.setState((prevState) => {
      return {
        ProfileFormField: {
          ...prevState.ProfileFormField,
          working_language: value?.attributes.name === undefined ? '' : value?.attributes.name,
        },
      };
    });
  }

  handleDropdownData = (e: React.SyntheticEvent, value: any, fieldName: keyof ProfileInitialValues) => {
    if (fieldName === 'country') {
      this.handleCountryChange(value)
    }
    else if (fieldName === 'state') {
      this.handleStateChange(value)
    }
    else if (fieldName === 'city') {
      this.handleCityChange(value)
    }
    else if (fieldName === 'time_zone') {
      this.handleTimeChange(value)
    }
    else if (fieldName === 'working_language') {
      this.handleWorkingLanguageChange(value)
    }
    else {
      this.setState({ ProfileFormField: { ...this.state.ProfileFormField, [fieldName]: value?.title === undefined ? '' : value.title } })
    }
  }

  handlePasswordChange = (e: any) => {
    let value = e.target.value;
    this.setState({ PasswordFormField: { ...this.state.PasswordFormField, [e.target.name]: value.trimStart() } });
  };

  handleCloseSnackbar = () => {
    this.setState({
      showSnackbar: false,
      severity: undefined,
      message: "",
    });

    return true;
  };

  checkUniqueInterest = (otherInterestFields:any, interestsCategories:any) => {
    let valueArr = otherInterestFields.map(function (item:any) { return item.name });

    const isDuplicate = valueArr.some((item:any, idx:any) => valueArr.indexOf(item) != idx);
    if(isDuplicate) return true;

    let unique = false;
    otherInterestFields.forEach((other1: any) => {
      const check = interestsCategories.filter((other2: any) => (other1.name === other2.attributes.name));
      if(check.length) {
        unique = true;
      }
    });
    return unique;
  }

  handleUserInterestsAttributes = (selected_interest: any, otherInterestImpactFields: any, showOtherImpactFields: any,
    otherInterestSkillsFields: any, showOtherSkillsFields: any) => {
    let user_interests_attributes = [...selected_interest];
    if (otherInterestImpactFields.length && showOtherImpactFields === true) {
      const otherImpact = otherInterestImpactFields.map((item: any) => {
        return { interest_attributes: { ...item } }
      })
      user_interests_attributes = [...user_interests_attributes, ...otherImpact];
    }
    if (otherInterestSkillsFields.length && showOtherSkillsFields === true) {
      const otherSkills = otherInterestSkillsFields.map((item: any) => {
        return { interest_attributes: { ...item } }
      })
      user_interests_attributes = [...user_interests_attributes, ...otherSkills];
    }
    return user_interests_attributes
  }

  handleInterestValidation = (interest_attr: any) => {
    let flag = false;
    for (let item of interest_attr) {
      if (typeof item !== "string" && item.interest_attributes && item.interest_attributes.name === "") {
        flag = true
        this.setState({
          showSnackbar: true,
          message: t('Please fill other interest field.'),
          severity: "error",
        });
        break;
      }
    }
    return flag
  }

  //istanbul ignore next/
  handleProfileSubmit = (e: any) => {
    e.preventDefault();
    if (this.handleValidation(this.state.ProfileFormField)) {
      const { selected_interest, otherInterestImpactFields, showOtherImpactFields, otherInterestSkillsFields, showOtherSkillsFields, interestList, userInfo } = this.state
      const new_selected_interest = this.state.staticInterestSelectedList.filter((item: any) =>!userInfo?.interest_name?.some((interest: any) => interest.id == item.id))
      const removed_interests = userInfo?.interest_name.filter((item: any) =>!this.state.ProfileFormField.interest_name.some((interest: any) => interest.id == item.id))
      const new_selected_skills = this.state.staticSkillSelectedList.filter((item: any) =>!userInfo?.skill_name?.some((skill: any) => skill.id == item.id))
      const removed_skills = userInfo?.skill_name.filter((item: any) =>!this.state.ProfileFormField.skill_name.some((skill: any) => skill.id == item.id))    
      if(this.state.ProfileFormField.skill_name.length === 0){
        return this.setState({
              showSnackbar: true,
              message: t('Please select any skill.'),
              severity: "error",
            });
      }
      const user_interests_attributes = [...new_selected_interest, ...new_selected_skills]
      const removed_interest = [...removed_interests, ...removed_skills]
      let finalData = { ...this.state.ProfileFormField, 
        user_interests_attributes,
        removed_interest
       } as any;
       this.editProfile(finalData)
    } else {
      return this.setState({
        showSnackbar: true,
        message: t('Please fill out all mandatory fields'),
        severity: "error",
      });
    }
  }

  handlePasswordSubmit = (e: any) => {
    e.preventDefault();
    if (this.handlePasswordValidation(this.state.PasswordFormField)) {
     this.editPasswrod(this.state.PasswordFormField)
    } else {
      return this.setState({
        showSnackbar: true,
        message: t('Invalid password details'),
        severity: "error",
      });
    }
  }

  validateFormField = (field: ValidateFields, data: ProfileInitialValues | PasswordInitialValues) => {

    const name = field.key as keyof typeof data;
    if (data[name] === "") {
      return t(`${field.label}`)+ t(`is required`);
    }
    if (field.regexp && (!field.regexp.test(data[name] as string))) {
      return t(`${field.label}`) + t(` must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.`);
    }
    return true;
  }

  validateEditorText = (key: keyof ProfileInitialValues, data: ProfileInitialValues) => {
    if (data[key] === null || data[key] === undefined || data[key].replace(/<[^>]*>/g, '').trim().length === 0) {
      return t('It is required field')
    }
    return true
  }

  validatePhoneNumber = () => {
    const numberWithCode = `+${this.state.ProfileFormField.full_phone_number}`;
    const updatedDialCode = `+${this.state.phoneCountryObject.dialCode}`;
    const numberWithoutCode = numberWithCode.replace(`+${this.state.phoneCountryObject.dialCode}`, "").trim();

    const maxLength = this.state.phoneCountryObject.format?.slice(updatedDialCode.length)?.trim()?.split('.').length - 1;

    if (numberWithoutCode.length < maxLength) {
      return `Please add more ${maxLength - numberWithoutCode.length} digits`
    }
    else if (numberWithoutCode.length > maxLength) {
      return `Phone number can't be more than ${maxLength} digits`
    }
    return true
  }

  validateEditFormField = (field: ValidateFields, data: ProfileInitialValues) => {
    const name = field.key as keyof typeof data;
    if (name === 'experience' || name=== 'organizational_affilation') {
      return this.validateEditorText(name,data)
    }
    if(name === "full_phone_number"){
      return this.validatePhoneNumber()
    }
    if (data[name] === "" || data[name] === null) {
      return `It is required field`;
    }
    if (field.regexp && (!field.regexp.test(data[name] as string))) {
      return `${field.label} is in invalid format`;
    }
    return true;
  }

  handleValidation = (formState: ProfileInitialValues) => {
    const validateFields = [
      { key: 'full_name', label: "Full Name", regexp: /^[a-zA-Z ]{0,42}$/, errorKey: 'nameError' },
      { key: 'email', label: "Email", regexp: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, errorKey: 'emailError' },
      { key: 'full_phone_number', label: "Phone Number", regexp: null, errorKey: 'phoneError' },
      { key: 'country', label: "Country", regexp: null, errorKey: 'countryError' },
      { key: 'state', label: "State", regexp: null, errorKey: 'stateError' },
      { key: 'city', label: "City", regexp: null, errorKey: 'cityError' },
      { key: 'preferred_language', label: "Preferred Language", regexp: null, errorKey: 'languageError' },
      { key: 'selected_interest', label: "Interested in", regexp: null, errorKey: 'selected_interest' },
      { key: 'time_zone', label: "Time zone", regexp: null, errorKey: 'zoneError' },
      { key: 'working_language', label: "Working Language", regexp: null, errorKey: 'workingLanguageError' },
      { key: 'organizational_affilation', label: "organizational_affilation", regexp: null, errorKey: 'affiliationsError' },
      { key: 'experience', label: "experience", regexp: null, errorKey: 'experienceError' },
      { key: 'about_yourself', label: "about_yourselft", regexp: null, errorKey: 'aboutError' },
    ];

    let isValid = true;
    let errorFields = { ...this.state.ProfileErrorField };
    validateFields.forEach((itm: ValidateFields) => {
      const res = this.validateEditFormField(itm, formState);
      errorFields = { ...errorFields, [itm.errorKey]: (res === true) ? "" : res };
      if (res !== true) {
        isValid = false;
      }
    });
    this.setState({ ProfileErrorField: errorFields });
    return isValid;
  };

  handlePasswordValidation = (formState: PasswordInitialValues) => {

    const validateFields = [
      { key: 'current_password', label: "Current Password", regexp: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d[\]{};:=<>_+^#$@!%*?&]{8,}$/, errorKey: 'currentPasswordError' },
      { key: 'new_password', label: "New Password", regexp: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d[\]{};:=<>_+^#$@!%*?&]{8,}$/, errorKey: 'newPasswordError' },
      { key: 'confirm_password', label: "Confirm Password", regexp: null, errorKey: 'confirmPasswordError' }];
    let isValid = true;
    let errorFields = { ...this.state.PasswordErrorField };
    validateFields.forEach((itm: ValidateFields) => {
      const res = this.validateFormField(itm, formState);
      errorFields = { ...errorFields, [itm.errorKey]: (res === true) ? "" : res };
      if (res !== true) {
        isValid = false;
      }
    });
    if (formState.new_password !== formState.confirm_password) {
      errorFields = { ...errorFields, confirmPasswordError: t('Confirm Password does not match') };
      isValid = false;
    }
    this.setState({ PasswordErrorField: errorFields });
    return isValid;
  };

  handleSelectInterest = (id: number) => {
    if (this.state.interestIds.includes(id)) {
      let idsArray = [...this.state.interestIds];
      let selectedIdsArray = [...this.state.selected_interest];
      idsArray = idsArray.filter(item => item !== id);
      const indxKey = selectedIdsArray.findIndex(item => item.interest_id === id)
      selectedIdsArray.splice(indxKey, 1)
      this.setState({ selected_interest: selectedIdsArray , interestIds: idsArray });
    } else {
      const interest_arrs = this.state.selected_interest;
      interest_arrs.push({ 'interest_id': id });
      const interest_id_arr = this.state.interestIds;
      interest_id_arr.push(id);
      this.setState({ selected_interest: interest_arrs , interestIds: interest_id_arr });
    }
  }

  handleShowFields = (type: string) => {
    if (type === "impact_area") {
      
        this.setState({ showOtherImpactFields: !this.state.showOtherImpactFields, otherInterestImpactFields: this.state.showOtherImpactFields? []: [{ ...otherInterestAddMoreField }]})
     
    } else {
        this.setState({ showOtherSkillsFields: !this.state.showOtherSkillsFields, otherInterestSkillsFields:this.state.showOtherSkillsFields ? []: [{ ...otherInterestAddMoreField }]})
    }
  }

  getInterestList = () => {
    const headerData = { "Content-Type": configJSON.validationApiContentType };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetInterest = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getInterestAPiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCountryList = () => {

    const headerObj = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetCountry = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCountryAPiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerObj)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getStateList = (country: string) => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetState = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCountryAPiEndPoint}/state_list?alpha2=${country}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCityList = (country:string, state: string) => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetCity = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCountryAPiEndPoint}/city_list?state_code=${state}&alpha2=${country}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getPBAConnections = () => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiPBAconnections = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPBAconnectionsEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getUserInterestList = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userInterestList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserInterestListApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getUserSkillList = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userSkillList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserSkillListApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getTimezonesList = () => {
    const headerObj = {
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiTimezones = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTimezonesApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerObj)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getWorkingLanguages = () => {
    const header = {
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.workingLanguageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.wokringLanguageApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  addKeyIntoWorkingLanguageList=(data: any[])=>{
    const newData = data.map((ele: any)=>{
      return ({
        ...ele,
        attributes:{
          ...ele.attributes,
          name: ele.attributes.language
        }
      })
    })
    return newData;
  }

  workingLanguageApiHandler = (responseJson: any) => {
      if (responseJson != null) {
        if (responseJson.data) {
          const updatedData = this.addKeyIntoWorkingLanguageList(responseJson.data)
          this.setState({workingLanguageList: updatedData});
        } else {
          this.setState({ workingLanguageList: [] })
        }
      }
  }


  editProfile = (data: any) => {
    let formData = new FormData();
    const stateres = data?.state !== null ? data?.state : "";
    const cityres = data?.city !== null ? data?.city : "";
    const experiences = data?.experience !== null ? data?.experience : "";
    const organisationres = data?.organizational_affilation !== null ? data?.organizational_affilation : "";

    formData.append('data[attributes][full_name]', data?.full_name);
    formData.append('data[attributes][email]', data?.email);
    formData.append('data[attributes][full_phone_number]', data?.full_phone_number);
    formData.append('data[attributes][number_visible]', data.number_visible);
    formData.append('data[attributes][country]', data?.country);
    formData.append('data[attributes][state]', stateres);
    formData.append('data[attributes][city]', cityres);
    formData.append('data[attributes][time_zone]', data.time_zone);
    formData.append('data[attributes][preferred_language]', data?.preferred_language);
    formData.append('data[attributes][working_language]', data.working_language);
    formData.append('data[attributes][about_yourself]', data?.about_yourself);
    formData.append('data[attributes][organizational_affilation]', organisationres);
    formData.append('data[attributes][experience]',experiences);
    console.log('testing', data.user_interests_attributes)
    data?.user_interests_attributes.forEach((i: any, index: number) => {
      if (!isNaN(i.id)) {
        formData.append(`data[attributes][user_interests_attributes][${index}][interest_id]`, i.id);
      } else {
        formData.append(`data[attributes][user_interests_attributes][${index}][interest_attributes][name]`,i.attributes.name)
        formData.append(`data[attributes][user_interests_attributes][${index}][interest_attributes][created_by_admin]'`, i.attributes.created_by_admin)
        formData.append(`data[attributes][user_interests_attributes][${index}][interest_attributes][interest_category]`, i.attributes.interest_category)
      }
    })
    data?.connections.map((connection:any,index:number)=>{
      formData.append(`data[attributes][connection_ids][]`,connection.id)
    })
    data?.removed_interest.forEach((i: any, index: number) => {
      formData.append(`data[attributes][remove_interests][]`, i.id);
    })
    if(this.state.imagePreview != null){
      formData.append(`data[attributes][photo]`,this.state.updatedProfileImage );
    }
    const header = {
      "token": this.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiEditProfile = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editProfileApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiPutMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  editPasswrod = (data: any) => {
    let formData = new FormData();
    formData.append('data[attributes][current_password]', data?.current_password);
    formData.append('data[attributes][new_password]', data?.new_password);
    formData.append('data[attributes][confirm_password]', data?.confirm_password);
    const header = {
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiEditPassword = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editPasswordApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiPutMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleNavigationBack=()=>{
    this.props.navigation.goBack()
  }
  // Customizable Area End
}
