import React from "react";
import {
    Typography,
    Container,
    Badge,
    Grid,
    Paper,
} from "@material-ui/core";
import "../../assets/index.scss";
import {edit, fileUploadIcon, galleryImage} from "../assets";
import {RestCreateNewGroupForm} from "./restCreateNewGroupForm.web"
import CreateNewGroupController, {
  Props,
} from "../CreateNewGroupController.web";
import Header from "../../../../components/src/Header.web";
import { t } from "i18next";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export default class CreateNewGroup extends CreateNewGroupController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render(){
  const {location, profilePhotoPreview, groupName, description, groupNameError, groupTypeError, value,
  interests, interestIds, showOtherImpactFields, showOtherSkillsFields, otherInterestImpactFields, impactInterestUniqueError,
  skillsInterestUniqueError, otherInterestSkillsFields, userInterestError, uploadCoverPhoto, uploadProfilePhoto, selected_interest,
  coverPhotoKey, iconPhotoKey, removedInterestIds, selectedInterestIds} = this.state  
  return(
    <>
       <Header type={""} onSearch={()=>{}}  navigation={this.props.navigation} profileImage={this.props.profileImage}
       notifyCount={Number(localStorage.getItem("notification_unread_count"))}
       />
      <div style={{ background: "linear-gradient(to bottom, rgba(231,239,252,1) 40%, rgba(255,255,255,1) 100%)", width: '100%', height: '100%' }}>
        <Grid container className="edit_create_group">
          <Grid container spacing={2}>

            <Grid className="form-box" item xs={12} lg={12} sm={12} md={12}>
              <Paper className="paper-box">
                <div className="edit_profile_back_container">
                  <Badge style={{ cursor: 'pointer' }} data-test-id='back-arrow-icon-edit'
                    onClick={() => { this.props.navigation.goBack() }}
                  >
                    <ArrowBackIcon
                      className="arrow_button"
                    ></ArrowBackIcon>
                  </Badge>
                  <Typography
                    variant="subtitle2"
                    className="edit_profile_back_text"
                    data-test-id='back-arrow-text-edit'
                    onClick={() => { this.props.navigation.goBack() }}
                  >{location === '/CreateNewGroup' ? t('Create New Group') : t('Edit Group')}</Typography>
                </div>
                <form>
                  <div className="grp_section">
                    {profilePhotoPreview ? (
                      <>
                        <img src={profilePhotoPreview} className="upload-profile" />
                        <input
                          accept="image/*"
                          type="file"
                          id="select-image"
                          style={{ display: "none" }}
                          onChange={(event) => this.handleSetState(event, "uploadProfilePhoto", "file")}
                        />
                        <label htmlFor="select-image">
                          <img src={edit} className="edit" />
                        </label>
                      </>
                    ) : (
                      <div className="upload-profile">
                        <input
                          accept="image/*"
                          type="file"
                          id="select-image"
                          style={{ display: "none" }}
                          onChange={(event) => this.handleSetState(event, "uploadProfilePhoto", "file")}
                        />
                        <label htmlFor="select-image" style={{ cursor: 'pointer' }}>
                          <img src={fileUploadIcon} />
                          <Typography className="uploadPhotoText">{t("Upload Photo")}</Typography>
                        </label>
                      </div>
                    )}
                    <Grid className="upload_photo_limit_container">
                      <Typography
                        variant="subtitle2"
                        style={{
                          fontFamily: "Nunito-Regular",
                          fontSize: '12px',
                          fontWeight: 400,
                          lineHeight: '22px',
                          color: this.state.imageError ? '#F97171' : '#475569'
                        }}
                      >
                        {t("Upload jpg/png up to 10mb")}
                      </Typography>
                    </Grid>
                  </div>
                  <RestCreateNewGroupForm handleOpen={this.props.handleOpen} groupName={groupName} groupNameError={groupNameError} description={description}
                    groupTypeError={groupTypeError} value={value} interests={interests} interestIds={interestIds} showOtherImpactFields={showOtherImpactFields}
                    showOtherSkillsFields={showOtherSkillsFields} otherInterestImpactFields={otherInterestImpactFields}
                    impactInterestUniqueError={impactInterestUniqueError} otherInterestSkillsFields={otherInterestSkillsFields}
                    skillsInterestUniqueError={skillsInterestUniqueError} userInterestError={userInterestError} handleSetState={this.handleSetState}
                    handleSelectInterest={this.handleSelectInterest} handleShowFields={this.handleShowFields} handleFormFields={this.handleFormFields}
                    handleOtherFields={this.handleOtherFields} uploadCoverPhoto={uploadCoverPhoto} uploadProfilePhoto={uploadProfilePhoto}
                    selected_interest={selected_interest} handleCloseGroupType={this.handleCloseGroupType} iconPhotoKey={iconPhotoKey}
                    handleOpenGroupType={this.handleOpenGroupType} openOtherComponent={this.props.openOtherComponent} coverPhotoKey={coverPhotoKey}
                    removedInterestIds={removedInterestIds} selectedInterestIds={selectedInterestIds}
                    handleGroupSetting={this.handleGroupSetting}
                  />
                </form>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  )
}
}
